"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";
import PageDefaultError from "@/src/sections/error/default-error-view";
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);
  return <PageDefaultError reset={reset} data-sentry-element="PageDefaultError" data-sentry-component="Error" data-sentry-source-file="error.tsx" />;
}